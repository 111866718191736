import React, { useState } from 'react';

import metaDataFile from '../../meta.json';



const FileContent = ({
  selectedFile,
  setSelectedFile,
  currentFileIndex,
  isReadMode,
  handlePreviousFile,
  handleNextFile,
  handleToggleReadMode,
  handleSaveFile,
  statusMessage,
  renderStatusMessage,
  newMessage,
  setNewMessage,
  handleKeyDown,
  handleSendMessage
}) => {
  const [isSummaryPopupVisible, setIsSummaryPopupVisible] = useState(false);
console.log('metaDataFile', metaDataFile)
  const handleToggleSummaryPopup = () => {
    setIsSummaryPopupVisible(!isSummaryPopupVisible);
  };

  if (!selectedFile) {
    return <div>No chapter selected</div>;
  }

  return (
    <div className="main-panel chapter-content">
      <div className="header">
        <h1>🤖 Skynet Terminal 🤖</h1>

        {/* code Summary Button */}
        <h2>File {currentFileIndex}</h2> {/* File number displayed here */}

        <button className="read-mode-button mobile-only" onClick={handleToggleReadMode}>
          {isReadMode ? '[-]' : '[+]'}
        </button>
        <div className="chapter-navigation">
          <button onClick={handlePreviousFile} disabled={!selectedFile}>
            Previous
          </button>
          <button onClick={handleNextFile} disabled={!selectedFile}>
            Next
          </button>
        </div>
        <button className="desktop-only" onClick={handleSaveFile}>
          Save File
        </button>
      </div>

      <textarea
        className={`selected-chapter ${isReadMode ? 'read-mode' : ''}`}
        value={selectedFile || ''} // Ensure safe access to content
        // onChange={(e) => setSelectedFile({ ...selectedFile, content: e.target.value })}
        onChange={(e) => setSelectedFile(e.target.value)}
      />

      {renderStatusMessage && renderStatusMessage()}

      <div className="send-container">
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Type a message..."
        />
        <button onClick={handleSendMessage}>Send</button>
      </div>

      {statusMessage.text && (
        <div className={`status-message ${statusMessage.type}`}>
          {statusMessage.text}
        </div>
      )}

      {/* code Summary Popup */}
      {isSummaryPopupVisible && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h2>code Summary</h2>
            <button onClick={handleToggleSummaryPopup}>Close</button>
            <div>
      <h1>{metaDataFile.code_summary.title}</h1>
      <p>{metaDataFile.code_summary.plot_overview}</p>
      <div>
        {metaDataFile.code_summary.sections.map((section, index) => (
          <div key={index} style={{ marginBottom: '20px' }}>
            <h1>{section.title}</h1>
            <p>{section.content}</p>
          </div>
        ))}
      </div>
    </div>

          </div>
        </div>
      )}
    </div>
  );
};

export default FileContent;
