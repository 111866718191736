import React from 'react';

const FilesList = ({
  chapters = [], // Default to an empty array
  currentFileIndex,
  handleFileClick,
  checkedFiles,
  setCheckedFiles,
  selectAllChecked,
  setSelectAllChecked,
  handleSummarizeSelectedFiles
}) => {
  const handleCheckboxChange = (index) => {
    const updatedCheckedFiles = {
      ...checkedFiles,
      [index]: !checkedFiles[index],
    };
    setCheckedFiles(updatedCheckedFiles);
    const allChecked = Object.values(updatedCheckedFiles).every((val) => val === true);
    setSelectAllChecked(allChecked);
    localStorage.setItem('checkedFiles', JSON.stringify(updatedCheckedFiles));
  };

  const handleSelectAllChange = () => {
    const newCheckedState = !selectAllChecked;
    const updatedCheckedFiles = chapters.reduce((acc, _, index) => {
      acc[index] = newCheckedState;
      return acc;
    }, {});
    setCheckedFiles(updatedCheckedFiles);
    setSelectAllChecked(newCheckedState);
    localStorage.setItem('checkedFiles', JSON.stringify(updatedCheckedFiles));
  };

  return (
    <div className="left-panel">
      <h3>Files</h3>
      <div className="select-all-container">
        <input
          type="checkbox"
          checked={selectAllChecked}
          onChange={handleSelectAllChange}
        />
        <label>Select All</label>
      </div>
      <ul>
        {Array.isArray(chapters) && chapters.length > 0 ? (
          chapters.map((chapter, index) => (
            <li
              key={index}
              className={`chapter-item ${index === currentFileIndex ? 'active' : ''}`}
            >
              <input
                type="checkbox"
                checked={checkedFiles[index] || false}
                onChange={() => handleCheckboxChange(index)}
              />
              <span onClick={() => handleFileClick(chapter, index)}>
                File {index + 1}
              </span>
            </li>
          ))
        ) : (
          <li>No chapters available</li>
        )}
      </ul>
      <button onClick={handleSummarizeSelectedFiles} className="button summarize-button">
        Summarize Selected Files
      </button>
    </div>
  );
};

export default FilesList;
