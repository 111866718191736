import React, { useState, useEffect, useRef } from 'react';
import FilesList from './components/FilesList/FilesList';
import FileContent from './components/FileContent/FileContent';
import MetaData from './components/MetaData/MetaData';
import Tools from './components/Tools/Tools';
import Queue from './components/Queue/Queue';
import Footer from './components/Footer/Footer';
import WebSocketManager from './components/WebSocket/WebSocketManager';
import chaptersData from './code.json';
import metaDataFile from './meta.json';
import { v4 as uuidv4 } from 'uuid';

import './App.css';

const App = () => {
  const [chapters, setFiles] = useState([]); // Default to an empty array
  const [selectedFile, setSelectedFile] = useState('');
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [metaData, setMetaData] = useState({});
  const [connectionStatus, setConnectionStatus] = useState('Disconnected');
  const [checkedFiles, setCheckedFiles] = useState({});
  const [selectAllChecked, setSelectAllChecked] = useState(true);
  const [showProgressWindow, setShowProgressWindow] = useState(false);
  const [isReadMode, setIsReadMode] = useState(false);
  const [statusMessage, setStatusMessage] = useState({ text: '', type: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [summaries, setSummaries] = useState(JSON.parse(localStorage.getItem('summaries')) || {});
  const [isQueueVisible, setIsQueueVisible] = useState(false);
  const [queuedTasks, setQueuedTasks] = useState(JSON.parse(localStorage.getItem('queuedTasks')) || []);
  const [pasteText, setPasteText] = useState('');
  const [isSummaryMode, setIsSummaryMode] = useState(false);
  const socket = useRef(null);

  useEffect(() => {
    console.log("Loaded chaptersData:", chaptersData);
    const initializedFiles = Array.isArray(chaptersData) ? chaptersData : [];
    setFiles(initializedFiles);
  
    setMetaData(metaDataFile || {});
  
    const storedCheckboxes = JSON.parse(localStorage.getItem('checkedFiles')) || {};
    setCheckedFiles(storedCheckboxes);
  
    const initialCheckedFiles = initializedFiles.reduce((acc, _, index) => {
      acc[index] = true;
      return acc;
    }, {});
    setCheckedFiles(initialCheckedFiles);
    setSelectAllChecked(true);

    if (initializedFiles.length > 0) {
      const storedFiles = JSON.parse(localStorage.getItem('chapters')) || initializedFiles;
      setFiles(storedFiles);
      setSelectedFile(storedFiles[0].content);
      setMessages([{ author: 'System', content: storedFiles[0].content }]);
    }
  }, []);
  

  const handleFileClick = (chapter, index) => {
    setCurrentFileIndex(index);
    setSelectedFile(chapter.content);
    setMessages([{ author: 'System', content: chapter.content }]);
    setStatusMessage({ text: '', type: '' });
  };

  const handlePreviousFile = () => {
    if (currentFileIndex > 0) {
      handleFileClick(chapters[currentFileIndex - 1], currentFileIndex - 1);
    }
  };

  const handleNextFile = () => {
    if (currentFileIndex < chapters.length - 1) {
      handleFileClick(chapters[currentFileIndex + 1], currentFileIndex + 1);
    }
  };

  const handleSendMessage = () => {
    if (!selectedFile.trim() || !newMessage.trim()) return;
    setIsSubmitting(true);
    setStatusMessage({ text: '', type: '' });

    const chapterIndex = currentFileIndex;
    const prompt = newMessage;
    const messageId = uuidv4();

    if (socket.current && socket.current.readyState === WebSocket.OPEN) {
      socket.current.send(
        JSON.stringify({
          mode: "service",
          service: 'updateFile',
          curl: null,
          message: { chapter: selectedFile, content: prompt, messageId: messageId, id: chapterIndex, meta: metaDataFile, template: '' }
        })
      );

      const newTask = { messageId, chapterIndex, prompt, status: 'pending', progress: 1 };
      setQueuedTasks((prevTasks) => {
        const updatedTasks = [...prevTasks, newTask];
        localStorage.setItem('queuedTasks', JSON.stringify(updatedTasks));
        return updatedTasks;
      });
    } else {
      setIsSubmitting(false);
      setStatusMessage({ text: 'Error!', type: 'error' });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') handleSendMessage();
  };


  return (
    <div className="app-container">
      <FilesList
        chapters={chapters}
        currentFileIndex={currentFileIndex}
        handleFileClick={handleFileClick}
        checkedFiles={checkedFiles}
        setCheckedFiles={setCheckedFiles}
        selectAllChecked={selectAllChecked}
        setSelectAllChecked={setSelectAllChecked}
        handleSummarizeSelectedFiles={() => setIsSummaryMode(true)}
      />
      <FileContent
        chapters={chapters}
        metaDataFile={metaDataFile}
        selectedFile={selectedFile}
        currentFileIndex={currentFileIndex + 1}
        setSelectedFile={setSelectedFile}
        isReadMode={isReadMode}
        handlePreviousFile={handlePreviousFile}
        handleNextFile={handleNextFile}
        handleToggleReadMode={() => setIsReadMode(!isReadMode)}
        handleSaveFile={() => {
          const updatedFiles = [...chapters];
          updatedFiles[currentFileIndex] = { ...updatedFiles[currentFileIndex], content: selectedFile };
          setFiles(updatedFiles);
          localStorage.setItem('chapters', JSON.stringify(updatedFiles));
          setStatusMessage({ text: 'File saved!', type: 'success' });
        }}
        statusMessage={statusMessage}
        renderStatusMessage={() => isSubmitting ? <span className="status-message spinner">Processing...</span> : null}
        newMessage={newMessage}
        setNewMessage={setNewMessage}
        handleKeyDown={handleKeyDown}
        handleSendMessage={handleSendMessage}
      />
      <WebSocketManager
        socket={socket}
        connectionStatus={connectionStatus}
        setConnectionStatus={setConnectionStatus}
        queuedTasks={queuedTasks}
        setQueuedTasks={setQueuedTasks}
        setMessages={setMessages}
        setMetaData={setMetaData}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        setIsSubmitting={setIsSubmitting}
        setStatusMessage={setStatusMessage}
      />
      <div className="right-panel">

        <Tools
          chapters={chapters}
          metaData={metaData}
          setFiles={setFiles}
          setSelectedFile={setSelectedFile}
          setMessages={setMessages}
          setQueuedTasks={setQueuedTasks}
          summaries={summaries}
          setSummaries={setSummaries}
          pasteText={pasteText}
          setPasteText={setPasteText}
          handleClearSummaries={() => {
            localStorage.removeItem('summaries');
            setSummaries({});
          }}
          handlePasteTextSummarization={() => setIsSummaryMode(true)}
        />
        <button
          className="process-dashboard-btn"
          onClick={() => setIsQueueVisible(true)}
        >
          Process Dashboard
        </button>
        <MetaData
          metaData={metaData}
          setMetaData={setMetaData}
        />
        <Footer connectionStatus={connectionStatus} />
      </div>
      <Queue
        isQueueVisible={isQueueVisible}
        setIsQueueVisible={setIsQueueVisible}
        queuedTasks={queuedTasks}
        setQueuedTasks={setQueuedTasks}
        showProgressWindow={showProgressWindow}
        setShowProgressWindow={setShowProgressWindow}
      />
    </div>
  );
};

export default App;
